<template>
	<div
		class="edit-style-container"
		:class="{ 'edit-style-container--active': isActive }"
	>
		<ZyroSeparator class="separator" />
		<div class="edit-style-container__content z-body-small">
			<ZyroSvg name="styles-brush" />
			<span class="margin-left">{{ title }}</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.scss';

.separator {
	margin: 16px 0;
}

.margin-left {
	margin-left: 8px;
}
</style>
